import './App.css';
import {
  Routes,
  Route,
  BrowserRouter
} from "react-router-dom";
import Verify from './routes/verify';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/magiclink/verify" element={<Verify />} />
        <Route path="/*" element={<div />} />
      </Routes>
    </BrowserRouter>
  );

    
}

export default App;
